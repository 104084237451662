import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PrivacyPolicy from "../../layouts/PrivacyPolicy";

const PrivacyPolicyEn = () => {
  return (
    <Layout 
    lang="en-CA" 
    switchLink='/politique-de-confidentialite' 
    slider={false} 
    title="Privacy policy" >
      <Seo title=""
           description=""
           lang="en-CA"/>
      <PrivacyPolicy lang="en-CA" />
    </Layout>
  )
}

export default PrivacyPolicyEn
